import React from 'react';
import parse from 'html-react-parser';
import Spacing from '../Spacing';
import EnquaireLogo from "../EnquireLogo";

import "./styles.scss";

export default function SectionHeading({
  title = "",
  titleUp = "",
  titleDown = "",
  subTitle = "",
  subTitleClassName = "",
  center,
  fontsize = "32",
  fontWeight = "500",
  logo = false,
  logoSize = "50",
  style = {},
  children,
}) {
  return (
    <div
      className={`cs_section_heading ${center ? "text-center" : ""}`}
      style={{ ...style }}
    >
      {logo && <EnquaireLogo size={logoSize} />}
      {titleUp && (
        <>
          <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_2rem">
            {parse(titleUp)}
          </h3>
          <Spacing md="5" />
        </>
      )}

      {children && (
        <h2
          className={`cs_section_title cs_fs_${fontsize} m-0`}
          style={{ fontWeight }}
        >
          {" "}
          {children}
        </h2>
      )}

      {title && (
        <h2
          className={`cs_section_title cs_fs_${fontsize} m-0`}
          style={{ fontWeight }}
        >
          {title && parse(title)}
        </h2>
      )}
      {titleDown && (
        <>
          <Spacing md="5" />
          <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_32">
            {parse(titleDown)}
          </h3>
        </>
      )}
      {subTitle && (
        <>
          <Spacing md="25" />
          <p className={`m-0 ${subTitleClassName}`}>{parse(subTitle)}</p>
        </>
      )}
    </div>
  );
}
