import SectionHeading from "../SectionHeading";
import "./styles.scss";

const Values = () => {
  return (
    <section className="section values">
      <div class="container">
        <div className="card gradient-border">
          <div class="content-container">
            <div className="values-header">
              <SectionHeading
                title="<span className='gradient-color'>Our Values</span>"
                fontsize="4rem"
                center
              />
              <img
                src="images/about/mission.jpg"
                alt="Doctor holding a patient"
                className="values-img"
              />
            </div>
            <div className="content">
              <div>
                <div class="item-header cs_fs_40">
                  Patient Centered Innovation
                </div>
                <p class="item-description cs_fs_20">
                  We believe in creating technology that puts patients first,
                  ensuring that our solutions seamlessly integrate into
                  patient's life, resulting in enhanced care, improved health
                  outcomes, and safe and personalized delivery of experiences.
                </p>
              </div>
            </div>
            <div className="content">
              <div>
                <div class="item-header cs_fs_40">Empathy Driven Care</div>
                <p class="item-description cs_fs_20">
                  At the core of our technology is empathy. Our digital
                  solutions are designed to deliver compassionate care, striving
                  to ensure that every patient interaction feels supportive,
                  personal, and meaningful.
                </p>
              </div>
            </div>
            <div className="content">
              <div>
                <div class="item-header cs_fs_40">Integrity</div>
                <p class="item-description cs_fs_20">
                  We hold ourselves accountable to the highest ethical standards
                  in everything we do, from how we handle patient data to how we
                  communicate with healthcare providers and partners.
                </p>
              </div>
            </div>
            <div className="content">
              <div>
                <div class="item-header cs_fs_40 ">Collaboration</div>
                <p class="item-description cs_fs_20">
                  We believe that the best outcomes in healthcare are achieved
                  through teamwork. By fostering close partnerships with
                  healthcare facilities, medical personnel, patients, and
                  technology experts, we create solutions that seamlessly
                  integrate into existing workflows and truly enhance patient
                  care.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Values;
