import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const defaultValues = {
  firstname: "",
  lastname: "",
  institute: "",
  phone: "",
  email: "",
  linkedin: "",
};

const DemoRequestForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="demo_request_form_cont">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            First Name <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("firstname", { required: "First name is required" })}
            placeholder="Enter your first name"
          />
          {errors.firstname && (
            <span className="cs_error_color">{errors.firstname.message}</span>
          )}
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Last Name <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("lastname", { required: "Last name is required" })}
            placeholder="Enter your last name"
          />
          {errors.lastname && (
            <span className="cs_error_color">{errors.lastname.message}</span>
          )}
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Institute Name <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("institute", {
              required: "Institute name is required",
            })}
            placeholder="Enter your institute name"
          />
          {errors.institute && (
            <span className="cs_error_color">{errors.institute.message}</span>
          )}
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">Phone</label>
          <input
            className="cs_form_field"
            {...register("phone", {
              pattern: {
                value: /^\d{3}-\d{3}-\d{4}$/,
                message: "Phone number must be in the format xxx-xxx-xxxx",
              },
            })}
            placeholder="Enter your phone number e.g. xxx-xxx-xxxx"
          />
          {errors.phone && (
            <span className="cs_error_color">{errors.phone.message}</span>
          )}
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Email <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Please enter a valid email",
              },
            })}
            placeholder="Enter your email e.g. xyz@abc.com"
          />
          {errors.email && (
            <span className="cs_error_color">{errors.email.message}</span>
          )}
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            LinkedIn <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("linkedin", {
              required: "LinkedIn profile link is required",
              pattern: {
                value: /^https?:\/\/(www\.)?linkedin\.com\/.*$/,
                message: "Please enter a valid LinkedIn URL",
              },
            })}
            placeholder="Enter your LinkedIn profile link e.g. linkedin.com/in/username"
          />
          {errors.linkedin && (
            <span className="cs_error_color">{errors.linkedin.message}</span>
          )}
        </div>
        <Link
          to="#"
          className="cs_btn cs_style_1"
          onClick={(event) => {
            event.preventDefault();
            handleSubmit(onSubmit)();
          }}
        >
          <span>Submit</span>
          <i>
            <img src="images/icons/arrow_white.svg" alt="Icon" />
            <img src="images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </Link>
      </form>
    </div>
  );
};

export default DemoRequestForm;
