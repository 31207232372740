import "./styles.scss";

const EnquaireLogo = ({ size }) => {
  return (
    <div className="enquaire-logo">
      <img src="/favicon.png" alt="Enquaire Logo" width={`${size}px`} />
    </div>
  );
};

export default EnquaireLogo;
