import EnquaireLogo from "../EnquireLogo";
import Paragraph from "../Paragraph";
import "./styles.scss";

const ImageContainer = ({
  imgUrl,
  logo = false,
  logoSize = "30",
  noZoom,
  title,
  titleOnTop = false,
  titleStyle = {},
}) => {
  return (
    <div className="image-container">
      <div className="img-title title-up">
        {titleOnTop && (
          <>
            {logo && <EnquaireLogo size={logoSize} />}
            <Paragraph
              className="cs_fs_24"
              title={title}
              centered
              style={{
                margin: "0 0 30px",
                lineHeight: "30px",
                ...titleStyle,
              }}
            />
          </>
        )}
      </div>
      <div className={`image-wrapper ${noZoom ? "no-zoom" : ""}`}>
        <img src={imgUrl} alt={title} />
      </div>
      <div className="img-title title-below">
        {!titleOnTop && (
          <>
            {logo && <EnquaireLogo size={logoSize} />}
            <Paragraph
              title={title}
              centered
              style={{
                margin: "1.875rem 1.25rem 0",
                fontSize: "1.125rem",
                lineHeight: "1.875rem",
                ...titleStyle,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ImageContainer;
