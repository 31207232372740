import "./styles.scss";

const MuteIcon = () => {
  return (
    <svg
      className="mute-icon"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <linearGradient id="muteGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#c90c29" />
          <stop offset="100%" stopColor="#63000f" />
        </linearGradient>
      </defs>
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="url(#muteGradient)"
        stroke="none"
      >
        <path
          d="M2912 4957 c-17 -7 -418 -288 -891 -624 l-858 -612 -460 -3 -460 -3
 -49 -30 c-30 -19 -60 -49 -79 -79 l-30 -49 0 -997 0 -997 30 -49 c19 -30 49
 -60 79 -79 l49 -30 460 -3 460 -3 846 -603 c465 -331 859 -611 876 -622 23
 -15 47 -19 111 -19 75 0 86 3 125 30 59 42 97 99 109 165 7 37 9 796 8 2245
 -3 1997 -4 2193 -19 2226 -24 52 -78 110 -124 130 -46 21 -133 24 -183 6z"
        />
        <path
          d="M3772 3234 c-84 -42 -132 -121 -132 -216 1 -88 26 -126 197 -299
 l156 -159 -160 -162 c-181 -185 -199 -214 -191 -317 11 -152 155 -252 306
 -211 41 12 69 35 217 181 l170 168 160 -158 c88 -87 176 -167 195 -177 46 -25
 171 -26 216 -2 76 42 134 134 134 216 0 85 -16 109 -194 290 l-170 173 161
 162 c171 171 202 216 203 293 0 87 -55 179 -134 222 -45 24 -170 23 -216 -2
 -19 -10 -107 -90 -195 -177 l-160 -158 -170 168 c-136 133 -178 170 -211 179
 -66 19 -125 15 -182 -14z"
        />
      </g>
    </svg>
  );
};

export default MuteIcon;
