import { useEffect, useRef } from "react";

import "./styles.scss";

const CustomDivider = ({ title }) => {
  const dividerRef = useRef(null);

  useEffect(() => {
    const currentRef = dividerRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    });

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="divider-container" ref={dividerRef}>
      <div className="divider">
        <span className="divider-text">{title}</span>
      </div>
    </div>
  );
};

export default CustomDivider;
