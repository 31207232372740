import "./styles.scss";

const VolumeIcon = ({ size = "35" }) => {
  return (
    <svg
      className="volume-icon"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <linearGradient id="muteGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#c90c29" />
          <stop offset="100%" stopColor="#63000f" />
        </linearGradient>
      </defs>
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="url(#muteGradient)"
        stroke="none"
      >
        <path
          d="M2846 4943 c-10 -3 -400 -310 -867 -684 l-849 -678 -442 -3 c-437 -3
 -444 -3 -489 -26 -74 -36 -125 -87 -161 -160 l-33 -67 0 -765 0 -765 33 -67
 c36 -73 87 -124 161 -160 45 -23 52 -23 489 -26 l442 -3 848 -678 c467 -373
 859 -681 873 -684 75 -20 156 12 194 77 l25 43 0 2263 0 2263 -25 43 c-38 65
 -121 97 -199 77z"
        />
        <path
          d="M4249 4290 c-71 -21 -119 -87 -119 -165 0 -60 7 -72 104 -180 154
 -171 247 -307 337 -491 233 -478 270 -1026 103 -1533 -93 -282 -227 -509 -440
 -746 -97 -108 -104 -120 -104 -180 0 -115 101 -194 212 -165 34 9 60 29 120
 90 291 299 512 714 603 1135 165 764 -54 1558 -591 2134 -91 98 -149 124 -225
 101z"
        />
        <path
          d="M3770 3807 c-77 -25 -120 -83 -120 -160 0 -62 16 -91 100 -183 225
 -248 342 -556 342 -904 0 -345 -115 -652 -338 -899 -89 -100 -104 -126 -104
 -186 0 -81 48 -144 125 -164 48 -12 102 -2 143 27 42 31 168 180 235 280 65
 95 151 268 190 377 72 203 107 483 88 698 -34 382 -165 695 -410 982 -107 126
 -170 159 -251 132z"
        />
      </g>
    </svg>
  );
};

export default VolumeIcon;
