import DemoRequestModal from "../DemoRequest/DemoRequestModal";
import SectionHeading from "../SectionHeading";
import "./styles.scss";

const HomeSection1 = () => {
  return (
    <section className="home-section-1 section">
      <div className="container">
        <div className="home-section-1-wrapper">
          <div className="home-section-1-content">
            <SectionHeading
              title="Explore how our solution is helping hospitals cut readmissions."
              fontsize="28"
            />
            <SectionHeading
              title="Facilitating smooth patient recovery in a safe, secure, & reliable way"
              logo
              fontsize="28"
            />
            <SectionHeading
              title="Fully customizable follow-up care"
              logo
              fontsize="28"
            />
            <SectionHeading
              title="Simple integration into hospital workflows"
              logo
              fontsize="28"
            />
          </div>
          <div className="video-container">
            <img
              className="video-background"
              src="/images/home_1/video-background.png"
              alt="enquaire logo with white background"
            />
            <div className="cs_hero_btn_wrap">
              <DemoRequestModal />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSection1;
