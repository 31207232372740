import SectionHeading from "../SectionHeading";

import "./styles.scss";

const BannerStrip = ({ title }) => {
  return (
    <div className="banner-strip">
      <SectionHeading title={title} center />
    </div>
  );
};

export default BannerStrip;
