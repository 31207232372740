import parse from "html-react-parser";
import React from "react";

import BannerStrip from "../../BannerStrip";
import "./styles.scss";

export default function BannerSectionStyle({
  bgUrl,
  title = "",
  subTitle = "",
  aspectRatio = 3,
  bannerTitle,
  subTitleStyle = {},
}) {
  return (
    <section>
      <div
        className="hero-img cs_bg_filed"
        style={{
          backgroundImage: `url(${bgUrl})`,
          height: `calc(100vw/${aspectRatio})`,
        }}
      >
        <div className="container">
          <div className="cs_banner_text">
            <p className="cs_banner_title cs_fs_4rem cs_primary_color">
              {parse(title)}
            </p>
            {subTitle && (
              <p
                className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color"
                style={{ ...subTitleStyle }}
              >
                {parse(subTitle)}
              </p>
            )}
          </div>
        </div>
      </div>
      {bannerTitle && <BannerStrip title={bannerTitle} />}
    </section>
  );
}
