import React from "react";
import parse from "html-react-parser";

import "./styles.scss";

const imgWidth = {
  lg: "550",
  md: "390",
  sm: "250",
};

const titleSize = {
  lg: "32",
  md: "24",
  sm: "20",
};

export default function TeamMemberCard({
  imgUrl,
  name,
  designation = "",
  description = "",
  cardSize = "lg",
  hoverEffect = false,
  isClTeamMember = false,
}) {
  return (
    <div
      className={`team-member-card cs_team cs_style_1 cs_type_2 text-center cs_radius_20 overflow-hidden ${
        hoverEffect ? "hover-effect" : ""
      } ${isClTeamMember ? "cl-team-member" : ""}`}
    >
      <div className="card-image" style={{ width: `${imgWidth[cardSize]}px` }}>
        <img src={imgUrl} alt="Doctor" />
      </div>
      <div className={`cs_team_meta cs_white_bg card-size-${cardSize}`}>
        <div>
          <h3 className={`cs_member_name cs_fs_${titleSize[cardSize]}`}>
            {name}
          </h3>
          {designation && (
            <p
              className={`cs_member_designation cs_heading_color cs_medium ${
                cardSize === "sm" ? "cs_fs_13" : "cs_fs_16"
              }`}
            >
              {parse(designation)}
            </p>
          )}
          {description && (
            <p
              className={`cs_member_description ${
                cardSize === "sm" ? "cs_fs_13" : "cs_fs_16"
              }`}
            >
              {parse(description)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
