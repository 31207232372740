import HTMLReactParser from "html-react-parser/lib/index";
import "./styles.scss";

const Paragraph = ({
  title = "",
  centered = false,
  rightAligned = false,
  style,
  className = "",
}) => {
  let textAlign = "left";
  if (centered) {
    textAlign = "center";
  } else if (rightAligned) {
    textAlign = "right";
  }

  return (
    <p className={`paragraph ${className}`} style={{ textAlign, ...style }}>
      {HTMLReactParser(title)}
    </p>
  );
};

export default Paragraph;
