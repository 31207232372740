import { Link } from "react-router-dom";
import DemoRequestForm from "../DemoRequest/DemoRequestForm";
import SectionHeading from "../SectionHeading";

const DemoPage = () => {
  return (
    <section className="section demo-page">
      <div className="container">
        <div className="demo-page-container">
          <div className="demo-page-heading">
            <SectionHeading>
              Already have a demo access token?{" "}
              <Link
                to="https://demo.enquaire.com/login"
                className="cs_btn cs_style_1"
              >
                <span className="demo-btn cs_fs_16">Click here</span>
                <i>
                  <img src="images/icons/arrow_white.svg" alt="Icon" />
                  <img src="images/icons/arrow_white.svg" alt="Icon" />
                </i>
              </Link>
            </SectionHeading>
          </div>
          <SectionHeading
            title="Don't have a demo access token? Submit the form below and we will reach out with details."
            fontsize="24"
          />
          <DemoRequestForm />
        </div>
      </div>
    </section>
  );
};

export default DemoPage;
