import CustomDivider from "../CustomDivider";
import ProductFeatures from "../Product/ProductFeatures";
import SectionHeading from "../SectionHeading";
import "./styles.scss";

const productFeatures = [
  {
    imgUrl: "/images/home_1/cabg.jpg",
    title: "Coronary artery bypass graft",
  },
  {
    imgUrl: "/images/home_1/knee-hip-replacement.jpg",
    title: "Knee and hip replacement",
  },
  {
    imgUrl: "/images/home_1/spinal-fusion.jpg",
    title: "Spinal fusion",
  },
];

const HomeSection2 = () => {
  return (
    <section className="section">
      <div className="container">
        <CustomDivider
          title={
            <SectionHeading
              title="Areas of <span className='gradient-color'>Special</span> Focus"
              center
              fontsize="3rem"
              style={{ marginBottom: "0px" }}
            />
          }
        />
      </div>
      <ProductFeatures
        logo
        logoSize={40}
        productFeatures={productFeatures}
        title="We understand that each area of post-acute follow-up care deserves special attention. To deliver high quality and tailored care, we currently provide AI care packages in the following three areas. Contact us to learn about more areas that will be added soon."
        titleOnTop
        titleStyle={{ fontWeight: "500", margin: "unset" }}
      />
    </section>
  );
};

export default HomeSection2;
