import React from 'react';
import AutoScrollingCarousel from "../../AutoScrollingCarousel";
import Spacing from "../../Spacing";

import "./styles.scss";

export default function BrandsSection({ data, sectionTitle }) {
  return (
    <section className="section">
      <div className="brand-section container">
        {sectionTitle && (
          <>
            <p className="mb-0 brand-section-heading">{sectionTitle}</p>
            <Spacing md="40" />
          </>
        )}
        <AutoScrollingCarousel data={data} />
      </div>
    </section>
  );
}
