import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import About from "./components/Pages/About";
import Contact from "./components/Pages/Contact";
import DemoPage from "./components/Pages/DemoPage";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import Team from "./components/Pages/Team";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        {/* <Route path="products" element={<Products />} /> */}
        <Route path="team" element={<Team />} />
        <Route path="about" element={<About />} />
        <Route path="demo" element={<DemoPage />} />
        <Route path="contact" element={<Contact />} />
      </Route>

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
