import CustomDivider from "../CustomDivider";
import ProductFeatures from "../Product/ProductFeatures";
import SectionHeading from "../SectionHeading";

import "./styles.scss";

const productFeatures = [
  {
    imgUrl: "/images/home_1/avatars.jpg",
    title: "Customizable with multilingual support",
  },
  {
    imgUrl: "/images/home_1/phone-app.jpg",
    title:
      "Web and App based interfaces for engagement between healthcare personnel, patients, and AI avatars",
  },
  {
    imgUrl: "/images/home_1/ehr-integration-1.jpg",
    title: "Secure and seamless integration with hospital's EHR",
  },
];

const HomeSection3 = () => {
  return (
    <section className="home-section-3 section">
      <div className="container">
        <CustomDivider
          title={
            <SectionHeading
              title="Our <span className='gradient-color'>AI Care Packages</span> Include"
              center
              fontsize="3rem"
              style={{ marginBottom: "0px" }}
            />
          }
        />
      </div>
      <ProductFeatures productFeatures={productFeatures} columns={3} noZoom />
    </section>
  );
};

export default HomeSection3;
