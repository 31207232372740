import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header({ logoSrc, variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header
        className={`cs_site_header cs_style1 cs_sticky_header ${
          mobileToggle ? "cs_mobile_toggle_active" : ""
        } ${variant} ${isSticky ? "cs_active_sticky" : ""}`}
      >
        <div className="cs_main_header">
          <div className="cs_main_header_in">
            <Link className="cs_site_branding" to="/">
              <img src={logoSrc} alt="Logo" />
            </Link>
            <div className="cs_main_header_left">
              <nav className="cs_nav">
                <ul
                  className={`${
                    mobileToggle ? "cs_nav_list cs_active" : "cs_nav_list"
                  }`}
                >
                  <li className="menu-item-has-children">
                    <Link to="/" onClick={() => setMobileToggle(!mobileToggle)}>
                      Home
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/products"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      Products
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="/team"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/demo"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      Demo
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
                <span
                  className={
                    mobileToggle
                      ? "cs_menu_toggle cs_teggle_active"
                      : "cs_menu_toggle"
                  }
                  onClick={() => setMobileToggle(!mobileToggle)}
                >
                  <span></span>
                </span>
              </nav>
            </div>
            <div className="cs_main_header_right">
              <div className="cs_toolbox"></div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
