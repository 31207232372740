import parse from "html-react-parser";
import React from "react";

import "./styles.scss";
import EnquaireLogo from "../EnquireLogo";

export default function MissionSection({
  imgUrl,
  title = "",
  description = "",
  className = "",
}) {
  return (
    <div className={`mission-cont${className}`}>
      <div className="mission-content">
        <div className="cs_section_heading cs_style_1">
          <EnquaireLogo size="50" />
          <h2 className="cs_section_title cs_fs_48 m-0">{parse(title)}</h2>
        </div>
        <p className="card-description">{parse(description)}</p>
      </div>
      <div className="mission-img">
        <img src={imgUrl} alt="About" />
      </div>
    </div>
  );
}
